import { Token } from "@cronosdex/sdk"
import { CRX } from "../../constants"

interface CUSTOM_AMOUNT {
    tokenObj: Token,
    amount: number
}

export interface ProjectInfo {
    mintPriceinWCROAmount: number | undefined
    mintPriceinCustomAmount: CUSTOM_AMOUNT | undefined
    webSite: string | undefined
    customMintPage: string | undefined
    ebisusBay: string | undefined
    hasMetadata: boolean,
    tokenURIPrefix: string | undefined,
    imageURIPrefix: string | undefined,
}

export const CRXILLION_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  400,
    mintPriceinCustomAmount: { tokenObj: CRX, amount: 2 },
    webSite: 'https://nft.crodex.app/crxillion/index.html',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/crxillion',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const SPACE_CRXILLION_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  650,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://swap.crodex.app/#/mhub',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/space-crxillions',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CROUGARS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://crougar.com/',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/crougars',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}


export const CROUGARX_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  199,
    mintPriceinCustomAmount: undefined,
    customMintPage: undefined,
    webSite: 'https://lootpad.io/collection/crougarx',
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}


export const CMB_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  275,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cronosmb.com/',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-primate-business',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const METAPX_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  500,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.metaversepixels.app/',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/metapixels',
    hasMetadata: false,
    tokenURIPrefix: undefined,
    imageURIPrefix: "https://ipfs.metaversepixels.app/ipfs/",
}


export const CRONOGODS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  295,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.cronogods.com/',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/cronogods',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CRONOGODDESSES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.cronogods.com/cronogoddess',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/crono-goddesses',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const LOOTERS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://lootpad.io/collection/looters',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/looters',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const RECKLESS_ROBOTS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.recklessrobotsnft.com/',
    customMintPage: undefined,
    ebisusBay: 'https://app.ebisusbay.com/collection/reckless-robots',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const ICYCRO_NFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const ICY_VALKYRIES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  350,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CROKING_NFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  140,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://app.croking.net/boxes/all',
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BOBA_NFTEA_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://twitter.com/Boba_NFTea',
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROMELEONS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cromeleons.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cromeleons',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const ALIENFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.aliennft.ca/',
    ebisusBay: 'https://app.ebisusbay.com/collection/alie-nft',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}


export const THE_SPORTS_NFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  325,
    mintPriceinCustomAmount: undefined,
    webSite: 'http://www.thesportsnft.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/gold-partner',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CORGI_CLUB_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://corgistudio.io/',
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}


export const ZOMBABIES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  160,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.zombabies.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/zombabies',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROFLIPS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  350,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://croflips.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cro-flips',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOS_RAGDOLLS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  99,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.cronosragdolls.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-ragdoll',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOS_BOT_SOCIETY_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cronosbotsociety.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-bot-society',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRYPTOCHAD_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  50,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://chadnft.club/',
    ebisusBay: 'https://app.ebisusbay.com/collection/crypto-chads',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const SCARECRO_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  249,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://twitter.com/ScareCROsNFT',
    ebisusBay: 'https://app.ebisusbay.com/collection/scarecros-nft',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const MARIESCYBORGS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  400,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.mariescyborgs.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/maries-cyborgs',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}


export const BUSHIF_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  229,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://bushicro.io/',
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BUSHIM_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  199,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://bushicro.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/bushicro',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}


export const CRONOS_FOX_ELITE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cronosfoxelite.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-fox-elite',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}


export const CRONOS_BULLS_AND_BEARS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cronosfoxelite.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-bulls-bears',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BABY_CROGES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  100,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://babycroge.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/baby-croges',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const NOBUDDIES_GEN2_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  399,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://nobuddiesnft.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/nobuddies-gen2',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}


export const SMART_MARS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  20,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.smartmartians.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/smart-mars',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BRAINDEAD_MONKEYS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  240,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.braindeadmonkeys.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/braindead-monkeys',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CYBORG_BROTHERHOOD_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  449,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.cyborgbh.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cyborg-brotherhood',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRO_PUNKS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  100,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cropunks.club/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cro-punks-club',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const KARDIA_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.playkardia.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/kardia',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROARMY_DIAMONDHAND_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  250,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROWBOYS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  490,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://crowboys.online/',
    ebisusBay: 'https://app.ebisusbay.com/collection/genesis-crates',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOSVERSE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  600,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.thecronosverse.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronosverse',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BBELITE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  400,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const ALIENCRONFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  600,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://aliencro.tech/',
    ebisusBay: 'https://app.ebisusbay.com/collection/alien-cro',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const ARMYJACKALS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://croarmy.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cro-army-jackals',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const TRIPPY_TREASURE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  75,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://treasure.croarmy.app/',
    ebisusBay: 'https://app.ebisusbay.com/collection/trippy-treasure-chests',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const GOBLIN_CRO_PET_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  75,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://goblincrotown.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/goblin-cro-pet',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOS_CHIBIS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  200,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cronoschibis.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-chibis',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROPET_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  250,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.cropetnft.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/cropet-nft',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const ALIEN_APOCALYPSE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  250,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://apocalypse-nft.io/',
    ebisusBay: 'https://app.ebisusbay.com/collection/alien-apocalypse',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROVILIANS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  550,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://thecronosverse.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/crovilians',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const ICY_VIKING_TREASURE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  700,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.icysquidgame.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/icy-viking-treasure-chests',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const SQUID_CROC_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  60,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://twitter.com/SquidCrowge',
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BB_TREASURY_COINS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  459,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.busybears.club/',
    ebisusBay: 'https://app.ebisusbay.com/collection/bb-treasury-coins',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BABY_PHENIX_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  50,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://babyphenix.com/#/',
    ebisusBay: 'https://app.ebisusbay.com/collection/baby-phenix',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROHOGS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  550,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.crohogs.com/',
    ebisusBay: 'https://app.ebisusbay.com/collection/crohogs',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOS_WOMEN_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  420,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cronos-women.com/',
    ebisusBay: 'https://app.ebisusbay.com/drops/cronos-women',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROMAN_SOCIETY_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  420,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://cromansociety.art/',
    ebisusBay: 'https://app.ebisusbay.com/collection/croman-society',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRO_SUCCUBUS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  180,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://www.hypednftclub.com/crosuccubus',
    ebisusBay: 'https://app.ebisusbay.com/collection/cro-succubus',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const LA_BRIGADE_DE_CUISINE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  499,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BOB_ADVENTURES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  299,
    mintPriceinCustomAmount: undefined,
    webSite: "https://cbonft.com/",
    ebisusBay: "https://app.ebisusbay.com/collection/bob-adventures",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRO_OF_THRONES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  220,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.hypednftclub.com/croofthrones",
    ebisusBay: "https://app.ebisusbay.com/drops/cro-of-thrones",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const TEENAGE_PHENIX_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  75,
    mintPriceinCustomAmount: undefined,
    webSite: "https://corgistudio.io/collection/632f6fffcb805c9877c826db",
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOS_BOSSES_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  80,
    mintPriceinCustomAmount: undefined,
    webSite: "https://corgistudio.io/collection/633222b5756269b609d40d8c",
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const APES_UTOPIA_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  420,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.apesutopia.com/",
    ebisusBay: "https://app.ebisusbay.com/drops/apes-utopia",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const TRAPPAZOIDS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  275,
    mintPriceinCustomAmount: undefined,
    webSite: "https://trappazoids.com/",
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROARMY_JARHEADS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  150,
    mintPriceinCustomAmount: undefined,
    webSite: "https://armynft.app/",
    ebisusBay: "https://app.ebisusbay.com/collection/cro-army-jarheads",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BUSY_BEARS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  459,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.busybears.club/",
    ebisusBay: "https://app.ebisusbay.com/collection/busy-bears-club",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CHEFZ_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  225,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.cronossteakhouse.com/",
    ebisusBay: "https://app.ebisusbay.com/collection/chefz",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const REBEL_PUNKS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  480,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.metawarz.io/RebelPunks/",
    ebisusBay: "https://app.ebisusbay.com/drops/rebel-punks",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BAD_TREEHOUSE_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  100,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/badmeerkat",
    ebisusBay: "https://app.ebisusbay.com/collection/badtreehouse",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CROSSBREEDS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  10,
    mintPriceinCustomAmount: undefined,
    webSite: "http://crossbreeds.defigamecove.com/",
    ebisusBay: "https://app.ebisusbay.com/collection/crossbreeds",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRAZY_CROSSBREEDS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  180,
    mintPriceinCustomAmount: undefined,
    webSite: "http://crossbreeds.defigamecove.com/",
    ebisusBay: "https://app.ebisusbay.com/drops/crazy-crossbreeds",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const TRAPPED_CROCOW_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  10,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BAD_MEERKAT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  10,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const BAD_MEERKAT_DEGEN_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  30,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const MAFIA_INFO : ProjectInfo = {
    mintPriceinWCROAmount:  250,
    mintPriceinCustomAmount: undefined,
    webSite: "www.mafianftset.com",
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CRONOS_Y00TS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 50,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cronos-y00ts",
    hasMetadata: true,
    customMintPage: undefined,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const GDRTOKEN_FOUNDERS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    customMintPage: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const MAFIA_SAFE_HOUSE_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 10,
    mintPriceinCustomAmount: undefined,
    webSite: "www.mafianftset.com",
    ebisusBay: undefined,
    customMintPage: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CROLON_MARS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 10,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: "https://crolonmars.com/nft-platform/",
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const COLONEL_TENDIE_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 99,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.tendiescro.xyz/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/colonel-tendies",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CROFAM_LADIES_VIP_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 144,
    mintPriceinCustomAmount: undefined,
    webSite: "https://crofamladies.gitbook.io/crofam-ladies-wp",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/crofam-ladies-vip",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const GREEN_CARD_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 500,
    mintPriceinCustomAmount: undefined,
    webSite: "https://GreenStix.xyz",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const ROBOTMEERKAT_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 60,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/badmeerkat",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/robotmeerkat",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const REBEL_KANGAS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 89,
    mintPriceinCustomAmount: undefined,
    webSite: "https://roo.finance/Pesky-Kanga",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const THUNDER_NFT_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 89,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const PIXEL_CITY_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 300,
    mintPriceinCustomAmount: undefined,
    webSite: "https://pixelabs.art/#/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/pixel-city",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const OXLUCKLESS_V2_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 400,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/0xLuckless",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/0xluckless-v2-0",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const OXLUCKLESS_V1_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 400,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/0xLuckless",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/0xluckless",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const OXLUCKLESS_V1_5_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 400,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/0xLuckless",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/0xluckless-v1-5",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const PUMPED_SHINU_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/pumped-shinu",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const LADY_SHINU_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const ICY_NFT_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 500,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.icycro.org/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/icy",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const BLUMIES_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 99,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.blumiesofficial.com/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/blumies",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const BAD_BILLY_GOATS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 1,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.crossbreeds.app/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/bad-billy-goats",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const GHOST_OG_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 59,
    mintPriceinCustomAmount: undefined,
    webSite: "https://ghostelitenfs.com/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/ghost-og",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CRO_HAMSTER_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cro-hamsters",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const ARMY_100K_TOKEN_NFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const WICKED_AZZ_PAWNZ_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 125,
    mintPriceinCustomAmount: undefined,
    webSite: "https://corgistudio.io/collection/643756ddd91b158bc368b9a6",
    customMintPage: "https://corgistudio.io/collection/643756ddd91b158bc368b9a6",
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const COWZ_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cowz",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}


export const OXLUCKLESS_V2_2_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 650,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/0xLuckless",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/0xluckless-v2-2",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const COCKHEADZ_OG_FOUNDER_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 250,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cockheadz-og-founder-edition",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CRONK_OG_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 25,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cronk-og",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CRONOS_TOWERS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 290,
    mintPriceinCustomAmount: undefined,
    webSite: "https://cronostowers.online/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/drops/cronos-towers",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const DR_DAO_X_MUTANT_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 120,
    mintPriceinCustomAmount: undefined,
    webSite: "https://daogames.online/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/dr-dao-x-mutant",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const AI_LABS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 65,
    mintPriceinCustomAmount: undefined,
    webSite: "https://daogames.online/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/ai-labs",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const AI_EXPERIMENTS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 60,
    mintPriceinCustomAmount: undefined,
    webSite: "https://daogames.online/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/ai-experiments",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const DAOLIST_PASS_3D_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 300,
    mintPriceinCustomAmount: undefined,
    webSite: "https://daogames.online/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/daolist-pass-3d",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const DR_DAO_OG_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 500,
    mintPriceinCustomAmount: undefined,
    webSite: "https://daogames.online/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/dr-dao",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const RARE_PEPE_CULT_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 120,
    mintPriceinCustomAmount: undefined,
    webSite: "https://moonflow.club/collection/cronos/rare-pepe-cult",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const LIL_SPERMY_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 120,
    mintPriceinCustomAmount: undefined,
    webSite: "https://moonflow.club/collection/cronos/lil-spermy",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const STRANGE_CAMEL_SOCIETY_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 120,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.strangecamelsociety.com/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/drops/strange-camel-society",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CRONOS_GOATS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 68,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.crossbreeds.app/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cronos-goats",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const SPICY_CROPEPE_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 10,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.cropepe.com/",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const GREENSTIX_OG_CANDLE_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 249,
    mintPriceinCustomAmount: undefined,
    webSite: "https://greenstix.xyz",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/greenstix-og-candles",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CROODLE_APE_V2_INFIO : ProjectInfo = {
    mintPriceinWCROAmount: 35,
    mintPriceinCustomAmount: undefined,
    webSite: "https://corgistudio.io/collection/641eead87dd27dd1b057e345",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const ULTIMATE_X_MUTANTS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 75,
    mintPriceinCustomAmount: undefined,
    webSite: "https://linktr.ee/daolabs/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/ultimate-mutants",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const TIME_TROPS_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 39,
    mintPriceinCustomAmount: undefined,
    webSite: "https://tatastwistedtonics.com/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/time-troops",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const SHINY_CRO_PEPE_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 10,
    mintPriceinCustomAmount: undefined,
    webSite: "https://www.cropepe.com/",
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const DEGENZ_SKULL_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 130,
    mintPriceinCustomAmount: undefined,
    webSite: "https://degenzskullsnft.com/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/degenzskulls",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const GANGVERSE_SOCIAL_CLUB_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 1000,
    mintPriceinCustomAmount: undefined,
    webSite: "https://gangverse.club/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/gangverse-social-club",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const LIGHTWAVE_BOOSTER_INFO : ProjectInfo = {
    mintPriceinWCROAmount: 1,
    mintPriceinCustomAmount: undefined,
    webSite: "https://degen-consortium.netlify.app/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/lightwave-booster",
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const NEW_CRONOS_BULLS_AND_BEARS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  20,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://croarmy.app/#/cronos-bulls-and-bears',
    ebisusBay: 'https://app.ebisusbay.com/collection/cronos-bulls-bears',
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
    customMintPage: undefined,
}

export const CAPONEBEAR_INFO: ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: "https://capone-bear.netlify.app/",
    customMintPage: "https://capone-bear.netlify.app/",
    ebisusBay: undefined,
    hasMetadata: false,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const SHROOK_NFT_INFO: ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: undefined,
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: false,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const CAT_WIF_GAT_INFO: ProjectInfo = {
    mintPriceinWCROAmount: 100,
    mintPriceinCustomAmount: undefined,
    webSite: "https://cat-wif-gat.netlify.app/",
    customMintPage: undefined,
    ebisusBay: "https://app.ebisusbay.com/collection/cat-wif-gat",
    hasMetadata: false,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}

export const BURN_CRO_BEARS_AND_BULLS_INFO: ProjectInfo = {
    mintPriceinWCROAmount:  100,
    mintPriceinCustomAmount: undefined,
    webSite: 'https://swap.crodex.app/#/mhub',
    customMintPage: undefined,
    ebisusBay: undefined,
    hasMetadata: true,
    tokenURIPrefix: undefined,
    imageURIPrefix: undefined,
}